import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import analytics from '../../services/AnalyticsService';
import RenderableError from '../../types/interfaces/RenderableError';
import { API_HOST, API_TIMEOUT } from '../../util/api';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import LoadingPage, { LoadingStatus } from './LoadingPage';
import styles from './ProductPage.module.scss';
import RequestButton from './RequestButton/RequestButton';
import SizeSelector, { Size } from './SizeSelector/SizeSelector';

const getStocksApiUrl = `https://${API_HOST}/fitting/all-stocks?upc=`;

export default function ProductPage() {
  const { uniqueProductCode } = useParams<Params>();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.INACTIVE);
  const [onlineStoreProduct, setOnlineStoreProduct] = useState<Product>();
  const [apiError, setApiError] = useState<RenderableError>();
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColour, setSelectedColour] = useState('');

  useEffect(() => {
    setLoadingStatus(LoadingStatus.LOADING_PRODUCT_DATA);
    axios
      .get(getStocksApiUrl + uniqueProductCode, { timeout: API_TIMEOUT })
      .then(({ data }) => {
        setLoadingStatus(LoadingStatus.INACTIVE);

        if (data?.message === 'Product only has one size.') {
          setApiError({
            name: 'Unsupported Product',
            button: { URL: '/', text: 'Scan another item' },
            message: "We're sorry but the item you've scanned is only available in one size.",
          });
          return;
        }

        setOnlineStoreProduct(data);
        // TODO: Get the color more directly from the endpoint API
        setSelectedColour(data?.sizes?.[0]?.color);
      })
      .catch(error => {
        setLoadingStatus(LoadingStatus.INACTIVE);

        const button = { URL: '/', text: 'Try again' };
        let message =
          'An unexpected error occurred. Please try again or speak to a colleague for assistance';

        if (error?.code === 'ECONNABORTED') {
          setApiError({
            name: 'Barcode Query Timeout',
            button,
            message: 'The request timed out. Please check your connection and try again.',
          });
          return;
        }

        switch (error?.response?.status) {
          case 400:
            message =
              "That barcode is not a supported format - please make sure it's an item of " +
              'M&S Clothing';
            break;
          case 404:
            message = "No result found. We didn't quite catch this barcode.";
            break;
          case 502:
            message =
              'Unfortunately, it looks like our service is down - please try again later or ' +
              'speak to a colleague for assistance';
            break;
        }

        setApiError({ name: 'Barcode Query Error', button, message });
      });
  }, [uniqueProductCode]);

  // TODO: Update this once server has cleaned up the imageUrl field
  const imageUrl = onlineStoreProduct?.sizes[0]?.imageUrl;

  if (apiError) return <ErrorMessage {...apiError} />;

  if (loadingStatus !== LoadingStatus.INACTIVE)
    return (
      <div className={styles.productPage}>
        <LoadingPage status={loadingStatus} />
      </div>
    );

  return (
    <div className={styles.productPage}>
      <img src={imageUrl} alt={onlineStoreProduct?.name} crossOrigin="anonymous" />
      <h2>{onlineStoreProduct?.name}</h2>
      <SizeSelector
        type={onlineStoreProduct?.productType}
        sizes={onlineStoreProduct?.sizes}
        onChange={setSelectedSize}
      />
      <RequestButton
        product={{
          name: onlineStoreProduct?.name,
          colour: selectedColour,
          size: selectedSize,
          pdpUrl: onlineStoreProduct?.url,
          imageUrl,
        }}
        onStatusUpdate={response => {
          if ((response as RenderableError)?.message) setApiError(response as RenderableError);
          else setLoadingStatus(response as LoadingStatus);
        }}
      />
      <a
        href={onlineStoreProduct?.url}
        onClick={() => {
          analytics.logEvent('view_pdp', {
            colour: selectedColour,
            size: selectedSize,
            pdpUrl: onlineStoreProduct?.url,
            upc: uniqueProductCode,
          });
        }}
        target="_blank"
        rel="noreferrer"
      >
        Buy online
      </a>
    </div>
  );
}

type Params = {
  uniqueProductCode: string;
};

type Product = {
  name: string;
  productType: number;
  imageUrl: string;
  url: string;
  sizes: Size[];
};
