import { useTorch } from '../../../hooks';
import { ReactComponent as FlashOffIcon } from './icons/flash-off.svg';
import { ReactComponent as FlashIcon } from './icons/flash-on.svg';
import styles from './TorchButton.module.scss';

export default function TorchButton({ onActive, tabIndex }: Props) {
  const [torch, toggleTorch, torchAvailable] = useTorch();

  if (!torchAvailable) return <></>;

  return torch ? (
    <FlashOffIcon
      className={styles.off}
      onClick={toggleTorch}
      role="button"
      aria-label="Turn torch off"
      tabIndex={tabIndex}
    />
  ) : (
    <FlashIcon
      className={styles.on}
      onClick={() => {
        onActive?.();
        toggleTorch();
      }}
      role="button"
      aria-label="Turn torch on"
      tabIndex={tabIndex}
    />
  );
}

type Props = { onActive?(): void; tabIndex?: number };
