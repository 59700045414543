import { RefObject, useEffect, useMemo, useState } from 'react';

import BarcodeScanner from '../services/BarcodeScanner';
import ScannerError from '../types/abstract/ScannerError';
import { Barcode, ScannerStatus } from '../types/interfaces/Scanner';
import { onBack, onTabChange } from '../util/browser';

// TODO: See if the scanner can reliably be stopped & started between tab changes and page loads,
//       try using the single-frame option again and review: https://github.com/serratus/quaggaJS/issues/337
const rebootScanner = () => window.location.reload();

export default function useBarcodeScanner(container: RefObject<Element>) {
  const [status, onStatusUpdate] = useState<ScannerStatus>(ScannerStatus.Inactive);
  const [code, onFound] = useState<Barcode>();
  const [error, onError] = useState<ScannerError>();
  const scanner = useMemo(() => new BarcodeScanner({ onStatusUpdate, onFound, onError }), []);
  const controls = useMemo(
    () => ({
      start: () => scanner.start(),
      pause: () => scanner.pause(),
      stop: () => scanner.stop(),
      reset: () => {
        scanner.start();
        onFound(undefined);
        onError(undefined);
      },
    }),
    [scanner]
  );

  useEffect(() => {
    // So my webcam doesn't turn on each time webpack rebuilds! 😅
    if (process.env.NODE_ENV !== 'production' && document.hidden) return () => {};

    const listenerCleanups = [
      onTabChange({
        closed: () => scanner.stop(),
        opened: () => rebootScanner(),
      }),
      onBack(() => rebootScanner()),
    ];

    container.current && scanner.init(container.current);

    return () => {
      scanner.stop();
      listenerCleanups.forEach(cleanup => cleanup());
    };
  }, [container, scanner]);

  return { code, status, error, scanner: controls };
}
