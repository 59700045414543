import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import styles from './App.module.scss';
import { BarcodeScanner, CookieBanner, CookiePolicy } from './components';
import ProductPage from './components/ProductPage/ProductPage';
import ThankYouPage from './components/ProductPage/ThankYouPage';
import { browser } from './util/browser';

export default function App() {
  // iOS insists on requesting permission for camera access on *every* load, so there's an emphasis
  // to maintain visual consistency with the modals (which are styled after iOS).
  const [blurred, setBlurred] = useState(browser?.os === 'iOS');

  return (
    <div className={`${styles.app} ${blurred ? styles.blurred : ''}`}>
      <header>
        <h1>Fitting Room Assistant</h1>
      </header>
      <Switch>
        <Route path="/cookie-policy">
          <CookiePolicy />
        </Route>
        <Route path="/product/:uniqueProductCode">
          <ProductPage />
        </Route>
        <Route path="/thank-you">
          <ThankYouPage />
        </Route>
        <Route path="/">
          <main>
            <BarcodeScanner onLoad={() => setBlurred(false)} />
          </main>
          <footer>
            <CookieBanner />
          </footer>
        </Route>
      </Switch>
    </div>
  );
}
