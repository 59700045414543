import axios from 'axios';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import analytics from '../../../services/AnalyticsService';
import RenderableError from '../../../types/interfaces/RenderableError';
import { API_HOST, API_TIMEOUT } from '../../../util/api';
import { truncate, validateId } from '../../../util/strings';
import { LoadingStatus } from '../LoadingPage';
import styles from './RequestButton.module.scss';

const MAX_ID_LENGTH = 3;
const REQUEST_ENDPOINT = `https://${API_HOST}/fitting/request-item`;

export default function RequestButton({ product, onStatusUpdate }: Params) {
  const { uniqueProductCode } = useParams<{ uniqueProductCode: string }>();
  const history = useHistory();

  const requestProduct = (fittingRoomId: string) => {
    if (!validateId(fittingRoomId)) {
      setError(true);
      return;
    }

    analytics.logEvent('fitting_room_request', {
      fittingRoomId,
      upc: uniqueProductCode,
      ...product,
    });

    onStatusUpdate(LoadingStatus.LOADING_FITTING_ROOM_REQUEST);
    axios
      .post(REQUEST_ENDPOINT, { fittingRoomId, product }, { timeout: API_TIMEOUT })
      .then(() => history.push('/thank-you'))
      .catch(error => {
        const button = { URL: window.location.pathname, text: 'Go back' };
        let message =
          'An unexpected error occurred. Please try again or speak to a colleague for assistance';

        if (error?.code === 'ECONNABORTED') {
          onStatusUpdate({
            name: 'Fitting Room Request Timeout',
            button,
            message: 'The request timed out. Please check your connection and try again.',
          });
        }

        switch (error?.response?.status) {
          case 400:
            message = 'Invalid request - please try again or speak to a colleague for assistance.';
            break;
          case 502:
            message =
              'Unfortunately, it looks like our service is down - please try again later or ' +
              'speak to a colleague for assistance';
            break;
        }

        onStatusUpdate({ name: 'Fitting Room Request Error', button, message });
      });
  };

  const [fittingRoomId, setFittingRoomId] = useState<string>('');
  const [error, setError] = useState(false);

  const isDisabled =
    !product?.name || !product?.size || !product?.colour || !product?.imageUrl || !product?.pdpUrl;

  return (
    <div className={styles.requestButton}>
      <label htmlFor="fittingRoomId">
        <p>
          <strong>Fitting room ID</strong> is printed on the mirror
        </p>
        <input
          disabled={isDisabled}
          placeholder="eg 11"
          maxLength={MAX_ID_LENGTH}
          onChange={event => {
            if (error) setError(false);
            setFittingRoomId(truncate(event.target.value, MAX_ID_LENGTH));
          }}
          value={fittingRoomId}
          formNoValidate
          tabIndex={-1}
        />
      </label>
      {error && (
        <>
          <div className={styles.arrow} />
          <div role="alert" className={styles.errorMessage}>
            Invalid fitting room ID
          </div>
        </>
      )}
      <br />
      <button
        type="button"
        disabled={isDisabled || !fittingRoomId}
        onClick={() => requestProduct(fittingRoomId)}
      >
        Request to fitting room now
      </button>
    </div>
  );
}

type ProductRequest = {
  name: string;
  size: string;
  colour: string;
  imageUrl: string;
  pdpUrl: string;
};

type Params = {
  product: Partial<ProductRequest>;
  onStatusUpdate(status: LoadingStatus | RenderableError): void;
};
