import { ScannerComponentStatus, SearchStatus } from '../../types/enums/ScannerComponentStatus';
import { ScannerStatus } from '../../types/interfaces/Scanner';
import { Ellipsis } from '../Animations';
import { ReactComponent as BarcodeScanIcon } from './icons/barcode-scan.svg';
import styles from './StatusPanel.module.scss';

export default function StatusPanel({ status }: { status: ScannerComponentStatus }) {
  let title = 'Place barcode within frame';
  let titleEllipsis = false;
  let text = 'Scanning will start automatically';

  switch (status) {
    case ScannerStatus.Inactive:
      return <></>;
    case ScannerStatus.Scanning:
      [title, titleEllipsis, text] = ['Scanning', true, 'Please ensure the barcode is in focus'];
      break;
    case SearchStatus.Loading:
      [title, titleEllipsis, text] = ['Loading', true, 'Please wait while we find the product'];
  }

  return (
    <section className={styles.statusPanel}>
      <BarcodeScanIcon />
      <div className={styles.textWrapper}>
        <h2>
          {title}
          {titleEllipsis && <Ellipsis />}
        </h2>
        <span>{text}</span>
      </div>
    </section>
  );
}
