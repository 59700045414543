import { useState } from 'react';
import { Link } from 'react-router-dom';

import analytics from '../../services/AnalyticsService';
import styles from './CookieBanner.module.scss';

export default function CookieBanner() {
  const [trackingConsent, setTrackingConsent] = useState(analytics.getUserConsentStatus());

  const updateConsent = (userConsents: boolean) => {
    analytics.setUserConsentStatus(userConsents);
    setTrackingConsent(userConsents);
  };

  return trackingConsent === null ? (
    <section className={styles.cookieBanner} role="dialog">
      <h2>
        We use <Link to="/cookie-policy">analytics cookies</Link> to offer you a better browsing
        experience
      </h2>
      <div className={styles.buttons}>
        <button onClick={() => updateConsent(false)} type="button">
          Refuse cookies
        </button>
        <button onClick={() => updateConsent(true)} type="button">
          Accept cookies
        </button>
      </div>
    </section>
  ) : (
    <></>
  );
}
