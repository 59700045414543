import requestLoadingGIF from './icons/request-loading.gif';
import styles from './LoadingPage.module.scss';

export enum LoadingStatus {
  INACTIVE,
  LOADING_PRODUCT_DATA,
  LOADING_FITTING_ROOM_REQUEST,
}

export default function LoadingPage({ status }: { status: LoadingStatus }) {
  let statusText = '';

  switch (status) {
    case LoadingStatus.LOADING_PRODUCT_DATA:
      statusText = 'Product loading, please wait...';
      break;
    case LoadingStatus.LOADING_FITTING_ROOM_REQUEST:
      statusText = 'Sending fitting room request...';
      break;
  }

  return (
    <div className={styles.loadingPage}>
      <h2>{statusText}</h2>
      <img src={requestLoadingGIF} alt="Loading..." />
    </div>
  );
}
