import { useEffect } from 'react';

import analytics from '../../services/AnalyticsService';
import RenderableError from '../../types/interfaces/RenderableError';
import styles from './ErrorMessage.module.scss';
import { ReactComponent as ErrorIcon } from './icons/error-triangle.svg';

export default function ErrorMessage(error: RenderableError) {
  const { message, information = '', button } = error;

  useEffect(() => analytics.logError(error), [error]);

  return (
    <section className={styles.errorMessage}>
      <ErrorIcon />
      <h2>{message}</h2>
      <p>{information}</p>
      {button && (
        <a href={button.URL}>
          <button type="button">{button.text}</button>
        </a>
      )}
    </section>
  );
}
