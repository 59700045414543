import { useState } from 'react';

import styles from './SizeSelector.module.scss';

export type Size = {
  name: string;
  imageUrl?: string;
  skuId: string;
  size: string;
  onlineStockQuantity: number;
  onlineStockIndicator: string;
  inStoreStockQuantity: number;
};

type SizeCategory = {
  name: string;
  sizes: Size[];
};

export type ProductSizes = Size[] | SizeCategory[];

export enum ProductType {
  GENERIC,
  BRA,
  TROUSERS,
}

type Params = {
  sizes: ProductSizes | undefined;
  type: ProductType | undefined;
  onChange(selected: string): void;
};

const buttonShouldBeSquare = (text: string | undefined) => !!(text && text.length <= 3);

const getButtonStyles = (size: Size | SizeCategory, selected: Size | SizeCategory | null) => {
  const classNames = [];
  if (buttonShouldBeSquare(size.name)) classNames.push(styles.square);
  if (size.name === selected?.name) classNames.push(styles.selected);

  return classNames.join(' ');
};

function GenericSelector({ sizes, onChange }: Omit<Params, 'type'>) {
  const [selected, setSelected] = useState<Size | null>(null);

  return (
    <div className={styles.sizeSelector}>
      <h3>Select size</h3>
      {(sizes as Size[]).map(size => (
        <button
          key={size.name}
          type="button"
          disabled={size.inStoreStockQuantity < 1}
          className={getButtonStyles(size, selected)}
          onClick={() => {
            setSelected(size);
            onChange(size.name);
          }}
        >
          {size.name}
        </button>
      ))}
    </div>
  );
}

function CategorySelector({ sizes, type, onChange }: Params) {
  const [selectedCategory, setSelectedCategory] = useState<SizeCategory | null>(null);
  const [selected, setSelected] = useState<Size | null>(null);

  if (!selectedCategory)
    return (
      <div className={styles.sizeSelector}>
        <h3>
          <span>Step 1</span> Select {type === ProductType.BRA ? 'band size' : 'length'}
        </h3>
        <div
        // TODO: Fix trouser styles for inch-based lengths (e.g. '34');
        // TODO: Do we need custom styles for trousers now?
        // className={
        //   type === ProductType.TROUSERS && !buttonShouldBeSquare(sizes?.[0].name)
        //     ? styles.trouserCategories
        //     : ''
        // }
        >
          {(sizes as SizeCategory[])?.map(size => (
            <button
              key={size.name}
              type="button"
              disabled={!size.sizes?.some(({ inStoreStockQuantity }) => inStoreStockQuantity >= 1)}
              className={getButtonStyles(size, selectedCategory)}
              onClick={() => setSelectedCategory(size)}
            >
              {size.name}
            </button>
          ))}
        </div>
      </div>
    );

  let secondHeadingText = '';
  if (type === ProductType.BRA)
    secondHeadingText = selected ? `Cup size: ${selected.name}` : 'Select cup size';
  if (type === ProductType.TROUSERS)
    secondHeadingText = selected ? `Size: ${selected.name}` : 'Select size';

  return (
    <div className={styles.sizeSelector}>
      <h3>Selection: {selectedCategory.name}</h3>
      <button
        type="button"
        className={getButtonStyles(selectedCategory, selectedCategory)}
        onClick={() => setSelectedCategory(selectedCategory)}
      >
        {selectedCategory.name}
      </button>
      <button
        type="button"
        onClick={() => {
          setSelectedCategory(null);
          setSelected(null);
        }}
      >
        Edit
      </button>

      <h3>
        <span>Step 2</span> {secondHeadingText}
      </h3>
      {selectedCategory.sizes.map(size => (
        <button
          key={selectedCategory.name + size.name}
          type="button"
          disabled={size.inStoreStockQuantity < 1}
          className={getButtonStyles(size, selected)}
          onClick={() => {
            setSelected(size);
            onChange(`${selectedCategory.name}, ${size.name}`);
          }}
        >
          {size.name}
        </button>
      ))}
    </div>
  );
}

export default function SizeSelector({ sizes, type, onChange }: Params) {
  if (type === ProductType.GENERIC) return <GenericSelector sizes={sizes} onChange={onChange} />;

  return <CategorySelector type={type} sizes={sizes} onChange={onChange} />;
}
