import { forwardRef, RefObject, useState } from 'react';

import { Barcode, BarcodeFormat } from '../../../types/interfaces/Scanner';
import { stripNonDigits, truncate } from '../../../util/strings';
import styles from './Keyboard.module.scss';

const MIN_LENGTH = 8;
const MAX_LENGTH = 13;

const isValid = (code: string) => [MIN_LENGTH, MAX_LENGTH].includes(stripNonDigits(code).length);
const inferFormat = (code: string): Barcode => ({
  value: code,
  format: code.length === 8 ? BarcodeFormat.ean_8 : BarcodeFormat.ean_13,
});

/**
 * Must be rendered in the DOM, if you want to use the ref's .focus() method!
 */
const Keyboard = forwardRef<HTMLInputElement, Props>((props: Props, inputRef) => {
  const { isOpen, onClose, onSubmitted } = props;
  const [code, setCode] = useState('');

  // TODO Add checksum validation for manually-entered barcodes
  return (
    <form
      aria-hidden={!isOpen}
      className={`${styles.keyboard} ${isOpen && styles.open}`}
      onBlur={() => setTimeout(onClose)} // 👈 action should be deferred
      onSubmit={event => {
        event.preventDefault();
        (inputRef as RefObject<HTMLInputElement>).current?.blur(); // 👈 force ⌨️ close
        onSubmitted(inferFormat(code));
      }}
    >
      <input
        ref={inputRef}
        onChange={event => setCode(truncate(stripNonDigits(event.target.value), MAX_LENGTH))}
        value={code}
        placeholder="Enter barcode number"
        type="number"
        pattern="[0-9]*" // 👈 forces numpad in iOS
        formNoValidate
        tabIndex={-1}
      />
      <button type="submit" disabled={!isValid(code)}>
        Search
      </button>
    </form>
  );
});

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmitted(b: Barcode): void;
};

export default Keyboard;
