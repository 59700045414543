import { forwardRef, RefObject } from 'react';

import analytics from '../../../services/AnalyticsService';
import { Barcode } from '../../../types/interfaces/Scanner';
import styles from './ControlPanel.module.scss';
import { ReactComponent as ManualEntryIcon } from './icons/manual-entry.svg';
import { Keyboard, TorchButton } from './index';

const ControlPanel = forwardRef<HTMLInputElement, Props>((props: Props, keyboardRef) => {
  const { manual, onSetManual, onCodeEntered } = props;

  return (
    <>
      <Keyboard
        ref={keyboardRef}
        isOpen={manual}
        onClose={() => onSetManual(false)}
        onSubmitted={onCodeEntered}
      />
      {!manual && (
        <nav className={styles.controlPanel}>
          <ManualEntryIcon
            className={styles.manual}
            onClick={() => {
              analytics.logEvent('interaction', { button: 'manual_mode' });
              (keyboardRef as RefObject<HTMLInputElement>).current?.focus();
              onSetManual(true);
            }}
            role="button"
            aria-label="Enter barcode manually"
            tabIndex={0}
          />
          <TorchButton
            onActive={() => analytics.logEvent('interaction', { button: 'torch' })}
            tabIndex={0}
          />
        </nav>
      )}
    </>
  );
});

type Props = {
  manual: boolean;
  onSetManual(b: boolean): void;
  onCodeEntered(b: Barcode): void;
};

export default ControlPanel;
