import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useBarcodeScanner } from '../../hooks';
import analytics from '../../services/AnalyticsService';
import { SearchStatus } from '../../types/enums/ScannerComponentStatus';
import { Barcode, ScannerStatus } from '../../types/interfaces/Scanner';
import { KEYBOARD_ACTION_LATENCY } from '../../util/browser';
import { LoadingSpinner } from '../Animations';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from './BarcodeScanner.module.scss';
import { ControlPanel, Overlay, StatusPanel } from './index';

export default function BarcodeScanner({ onLoad }: { onLoad?: () => void }) {
  const scannerRef = useRef<HTMLDivElement>(null);
  const manualInputRef = useRef<HTMLInputElement>(null);
  const [manualMode, setManualMode] = useState(false);
  const [manualCode, setManualCode] = useState<Barcode | null>();
  const history = useHistory();
  const { scanner, code, status, error: scanError } = useBarcodeScanner(scannerRef);

  const toggleManual = (active: boolean) => {
    active ? scanner.pause() : setTimeout(() => scanner.start(), KEYBOARD_ACTION_LATENCY);
    active && manualInputRef.current?.focus();
    setManualMode(active);
  };

  useEffect(() => {
    const barcode = code || (manualMode && manualCode);
    if (barcode) {
      analytics.logEvent('search', { type: manualMode ? 'manual' : 'camera', ...barcode });
      history.push(`/product/${barcode.value}`);
    }
  }, [code, history, manualCode, manualMode]);

  if (scanError && !scanError.isSoft) return <ErrorMessage {...scanError} />;

  return (
    <div ref={scannerRef} className={styles.scanner}>
      <video muted playsInline onLoadedMetadata={onLoad} />
      <StatusPanel status={status} />
      {!manualMode && <Overlay status={status} />}
      {[ScannerStatus.Starting, SearchStatus.Loading].includes(status) ? (
        <LoadingSpinner />
      ) : (
        <ControlPanel
          ref={manualInputRef}
          manual={manualMode}
          onSetManual={toggleManual}
          onCodeEntered={setManualCode}
        />
      )}
    </div>
  );
}
