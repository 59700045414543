import styles from './CookiePolicy.module.scss';

export default function CookiePolicy() {
  return (
    <section className={styles.cookiePolicy}>
      <h2>What are analytics cookies?</h2>
      <p>
        We use analytics cookies in order to monitor the performance of our site and improve the
        quality of your experience.
      </p>
      <p>
        Our Web Analytics Tool, mParticle, optionally adds two Local Storage variables for this
        purpose. They help us to prepare aggregated and anonymous statistical reports on the
        experience of our visitors.
      </p>
      <p>
        You may accept or refuse them, via the banner displayed at the bottom of our website. If you
        decide not to express your agreement, by ignoring the question, we will NOT store the
        variables on your device and your usage data will not be transferred to our Analytics Tool.
      </p>
      <p>Your choice will not affect your ability to use our site.</p>
    </section>
  );
}
