import { useState } from 'react';

import { getMainVideoTrack } from '../util/camera';

interface ExperimentalCapabilities extends MediaTrackCapabilities {
  torch?: boolean;
}

export default function useTorch(on = false): [boolean, () => void, boolean] {
  const [torch, setTorch] = useState(on);
  const toggleTorch = () => setTorch(t => !t);
  const track = getMainVideoTrack();
  const capabilities = track?.getCapabilities?.() as ExperimentalCapabilities;

  if (!track || !capabilities?.torch) return [torch, toggleTorch, false];

  track.applyConstraints(({ advanced: [{ torch }] } as unknown) as MediaTrackConstraints);

  return [torch, toggleTorch, true];
}
