import { useHistory } from 'react-router-dom';

import styles from './ThankYouPage.module.scss';

export default function ThankYouPage() {
  const history = useHistory();

  return (
    <section className={styles.thankYouPage}>
      <h2>Thank you!</h2>
      <p>
        Please wait in this fitting room cubicle, we’ll bring your requested item to you shortly
      </p>
      <button type="button" onClick={() => history.goBack()}>
        Go back
      </button>
      <button type="button" onClick={() => history.push('/')}>
        Scan another clothing item
      </button>
    </section>
  );
}
