import axios from 'axios';
import debounce from 'debounce';

export type Product = { productUrl: string } | null;

const { NODE_ENV, REACT_APP_API_HOST, REACT_APP_STAGING_API_HOST, REACT_APP_REQUEST_TIMEOUT } =
  process.env;

const getApiHost = () => {
  const { host, hostname, port } = window.location;

  // Load a separate domain for Staging server.
  if (
    NODE_ENV === 'production' &&
    host !== 'scan.marksandspencer.com' &&
    host !== 'fit-assist.marksandspencer.com'
  )
    return REACT_APP_STAGING_API_HOST;
  if (REACT_APP_API_HOST) return REACT_APP_API_HOST;

  // For development builds, where REACT_APP_API_HOST isn't defined, we default to adding 1 to the
  // client's port number (as that's the local default for the server).
  return port ? `${hostname}:${parseInt(port, 10) + 1}` : hostname;
};

export const API_HOST = getApiHost();
export const API_TIMEOUT = REACT_APP_REQUEST_TIMEOUT
  ? parseInt(REACT_APP_REQUEST_TIMEOUT, 10)
  : 5000;

export const loadProductURL = debounce(
  async (barcode: string) => {
    try {
      return await axios.get(`https://${API_HOST}/?upc=${barcode}`, {
        timeout: API_TIMEOUT,
      });
    } catch (error: any) {
      // Did the request trip our own timeout?
      if (error?.code === 'ECONNABORTED') return { status: 504, data: null };
      return error?.response || { status: 500, data: null };
    }
  },
  API_TIMEOUT / 5,
  true
) as (barcode: string) => Promise<{ data: Product; status: number }>;

/**
 * In case we have a compromised server...
 */
export const visitProductPage = (productURL: string) => {
  const url = new URL(productURL);
  const safeURL = `https://www.marksandspencer.com${url.pathname}${url.search}`;
  const adobeAnalyticsParam = `${url.search ? '&' : '?'}intid=scanmns_barcode_scan`;

  window.location.href = safeURL + adobeAnalyticsParam;
};
